:root {
  --tile-open-color: #ccb;
  --tile-closed-color: #333;

  /* Default values for variable set by Javascript on the .board-viewport element */
  --tile-width-px: 50px;
  --tile-height-px: 50px;

  /* Default values for variable set by Javascript on the .board-camera element */
  --tile-size: 50px;

  /* Default values for variables set by Javascript on markers, pings, and other elements that appear on the board */
  --x: 0;
  --y: 0;

  /* Default value for variable set by Javascript on various elements. This is a pixel art icon of the symbols "[?!]" */
  --url: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYAQMAAADaua+7AAAABlBMVEX/AAD///9BHTQRAAAALklEQVR4AWOAAfv/f1CwjV0Ng4WMDYPlHBsGeyi2kwFioLhdHRiD1CGzUTAUAACvcx8+WNH9tgAAAABJRU5ErkJggg=='
}
