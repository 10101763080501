.tile-view {
    &.tile-open {
        background-color: var(--tile-open-color);
    }
    &.tile-closed {
        background-color: var(--tile-closed-color);
    }
    &:hover {
        opacity: 0.9;
    }

    border: 1px solid black;

    position: absolute;

    width: calc( 1px * var(--tile-width-px) );
    height: calc( 1px * var(--tile-height-px) );
    left: calc( 1px * var(--tile-width-px) * var(--x) );
    top: calc( 1px * var(--tile-height-px) * var(--y) );

    cursor: pointer;
}
