
.memberIcon {

    background-image: var(--url);
    background-size: cover;
    background-position: center top;
    width: var(--tile-width-px);
    height: var(--tile-height-px);
    border-radius: 50%;
    /*box-shadow: 2px 2px 5px rgba(0,0,0,0.2),*/
    border: var(--border-width) solid var(--ping-color);

    --ping-color: rgb(127,127,127);
}
