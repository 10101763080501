.board-viewport {
  position: relative;

  overflow: hidden;
}

.board-camera {
  position: absolute;
  top: 50%;
  left: 50%;
  transform-style: preserve-3d;

  transition: transform var(--camera-transition-ms) linear;

  /* Default camera settings */
  --camera-x: 0;
  --camera-y: 0;
  --camera-z: 0;
  --camera-zoom: 1;
  --camera-transition-ms: 0;
  --camera-yaw: 0;

  &.view-mode-3d {
    /*noinspection CssInvalidFunction*/
    transform:
      perspective(2000px)
      scale(var(--camera-zoom))
      rotateX(45deg)
      rotateZ(var(--camera-yaw))
      translate3d(
        calc(-1 * var(--tile-size) * var(--camera-x)),
        calc(-1 * var(--tile-size) * var(--camera-y)),
        calc(-1 * var(--tile-size) * var(--camera-z))
      );
  }
  &.view-mode-2d {
    /*noinspection CssInvalidFunction*/
    transform:
      scale(var(--camera-zoom))
      translate(
        calc(-1 * var(--tile-size) * var(--camera-x)),
        calc(-1 * var(--tile-size) * var(--camera-y))
      );
  }
}
