.RoomView {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;

    display: flex;
    flex-direction: column;
}
.RoomView>:nth-child(1) {
    flex: 0 0 auto;
}
.RoomView>:nth-child(2) {
    flex: 1 1 0;
}
.boardAndSidebar {
    display: grid;
    justify-content: stretch;
    align-content: stretch;

    /* anchor for a floating sidebar */
    position: relative;

    align-items: stretch;
    justify-content: stretch;
}
