.pinged {
  &:before {
    content: ' ';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: 5px solid var(--ping-color);
    border-radius: 50%;
    background: none;
    animation: pinged-expand calc( var(--duration-ms) * 1ms ) ease-out both
    , ping-fade-out calc( var(--duration-ms) * 1ms ) ease-in both;
  }

  &:after {
    content: ' ';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: solid var(--ping-color);
    border-radius: 50%;
    background: none;
    animation: pinged-expand calc( var(--duration-ms) * 1ms ) ease-out reverse both
    , ping-fade-out calc( var(--duration-ms) * 1ms ) ease-in reverse both;
  }
}

@keyframes pinged-expand {
  0% {
    width: 100%;
    height: 100%;
    border-width: 0;
  }
  100% {
    width: 200%;
    height: 200%;
    border-width: 10px;
  }
}

@keyframes ping-fade-out {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 100%;
  }
  100% {
    opacity: 0;
  }
}
