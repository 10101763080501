.show-on-hover-anchor {
  &:not(:hover) {
    .display-on-hover {
      display: none;
    }
    .opaque-on-hover {
      opacity: 0;
    }
    .visible-on-hover {
      visibility: hidden;
    }
  }
}
