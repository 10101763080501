.sidebar {
  position: absolute;
  top: 0;
  right: 0;
  transform: translateX(100%);

  width: 20%;
  height: 100%;

  overflow-y: auto;

  transition: transform 200ms;
}
.sidebar.open {
  transform: translateX(0%);
}
