.ping {
  position: absolute;
  left: calc( var(--tile-width-px) * var(--x) * 1px );
  top: calc( var(--tile-height-px) * var(--y) * 1px );

  &:before {
    content: ' ';
    position: absolute;
    top: 0;
    left: 0;
    width: 5px;
    height: 5px;
    transform: translate(-50%, -50%);
    border: 5px solid var(--color);
    border-radius: 50%;
    background: none;
    animation: ping-expand calc( var(--duration-ms) * 1ms ) ease-out both
      , ping-fade-out calc( var(--duration-ms) * 1ms ) ease-in both;
  }

  &:after {
    content: ' ';
    position: absolute;
    top: 0;
    left: 0;
    width: 5px;
    height: 5px;
    transform: translate(-50%, -50%);
    border: solid var(--color);
    border-radius: 50%;
    background: none;
    animation: ping-expand calc( var(--duration-ms) * 1ms ) ease-out reverse both
    , ping-fade-out calc( var(--duration-ms) * 1ms ) ease-in reverse both;
  }
}

@keyframes ping-expand {
  0% {
    width: 0;
    height: 0;
    border-width: 0;
  }
  100% {
    width: calc( var(--tile-width-px) * 1px );
    height: calc( var(--tile-height-px) * 1px );
    border-width: 10px;
  }
}

@keyframes ping-fade-out {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 100%;
  }
  100% {
    opacity: 0;
  }
}
