.marker {
  --aura-color: white;
  --bounce-offset: 0px;

  position: absolute;
  left: calc( var(--tile-width-px) * var(--x) * 1px );
  top: calc( var(--tile-height-px) * var(--y) * 1px - var(--bounce-offset) );

  box-sizing: border-box;
  width: calc( var(--tile-width-px) * 1px );
  height: calc( var(--tile-height-px) * 1px );

  background-image: var(--url);
  background-size: cover;
  background-position: center top;

  border: var(--border-width) solid white;
  border-radius: 50%;
  --border-width: 2px;

  &.selected {
    --aura-width: 10px;
    box-shadow: 0 0 calc(var(--aura-width)/2) calc(var(--aura-width)/2) var(--aura-color);
  }
}

.board-camera.view-mode-3d .marker {
  /* ↓ Suppress IntelliJ check - it's freaking out seeing calc() expressions inside of translateZ and rotateZ */
  /*noinspection CssInvalidFunction*/
  transform:
    /* Undo the camera rotations so the pawns always face the camera */
    translateZ( calc(0.5 * var(--tile-size)) )
    rotateZ( calc(-1 * var(--camera-yaw)) )
    rotateX( -45deg );
  /* Smoothly animate the pawns as the camera rotates so they don't just "jump" between the 3D and 2D positions */
  transition: transform var(--camera-transition-ms) linear;
}

.marker-view {
  width: 50px;
  height: 50px;

  background-image: var(--url);
  background-size: cover;
  background-position: center top;

  border: var(--border-width) solid white;
  border-radius: 50%;
  --border-width: 2px;

  box-shadow: 0 2px 5px rgba(0,0,0,0.2);
}
